import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../../contexts/AppContext';
import axios from 'axios';

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  
useEffect(() => {
  fetchTestimonials(); // Call the async function within useEffect
  // console.log('projects',projects)
  //fetchArticles(); // Call the async function within useEffect
}, []);

const fetchTestimonials = async () => {
    try {
        const response = await axios.get('https://www.eldsmw.org:5000/api/testimonials');
        const data = response.data;
        console.table('testimonials  API Response sir :', data);
        const page= data[0];
        setTestimonials(data);
    } catch (error) {
        console.error('Error fetching data:', error);
        return { count: 0, result: null, error: 'Failed to fetch data. Please try again later.', };
    }
}

  return (
    <div class="border border-2 rounded-lg  mx-4 my-8">
      <section class="text-center">
        {/* <h2 class="mb-12 text-3xl font-bold">Testimonials</h2> */}
        <div class="text-center max-w-xl mx-auto">
            <h1 class="text-3xl md:text-4xl font-bold mb-5 text-gray-600">What people are saying<br/>about ELDS.</h1>
            <h3 class="text-xl mb-5 font-light">Real testimonies of impacted lives.</h3>
            <div class="text-center mb-10">
                <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
            </div>
        </div>
        <div class="flex flex-wrap justify-center justify-content: gap-8 space-between" style={{ flexDirection: 'row' }}> {/* New container  */}
        {testimonials && testimonials.slice(0, 3).map((testimonial) => (
            <div className="mb-12 md:mb-0 "key={testimonial.id}>
              <div class="mb-6 flex justify-center">
              <img src={testimonial?.photo_url}
                  alt=""
                  class="w-32 rounded-full shadow-lg dark:shadow-black/20" 
                  />
             
                
               
              </div>
              <h5 class="mb-0 text-lg font-bold">{testimonial?.name} - {testimonial?.address}</h5>
              <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
              {testimonial?.occupation}
              </h6>
              <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
              {testimonial?.address}
              </h6>
              {/* content */}
              <div className=''style={{ maxWidth: '300px' }}>
                <p class="mb-4 text-justify">
             
                <span className="text-gray-500 text-justify"dangerouslySetInnerHTML={{ __html: testimonial?.content  || '' }}></span> 
                       
                </p>
              </div>
              <ul class="mb-0 flex justify-center">
                {/* Star icons */}
              </ul>
            </div>
          ))}
          </div>

      </section>
    </div>
  );
};

export default Testimonial;
