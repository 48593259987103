import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';


const EditPage = ({ rowData }) => {
// const [page, setPage] = useState([{ name: '',  title: '', description:'',id:'', parent:'' ,userId:0 ,img:'',imgType:'',displayImg:'',imgUrl:'',}]);
const [page, setPage] = useState({ pageName: '', title: '', description:'', id:'', parentPage:'' , userId: 0, img:'', imgType:'', displayImg:'', imgUrl:'' });

const [parent, setParent] = useState([]);// State for pages
const [value, setValue] = useState('');
const [isLoading,setIsLoading]=useState(false);
const { userData, pages,updateCount,setUpdateCount} = useContext(AppContext);

useEffect(() => {
  // console.log('page id: ',pageId)
  setParent(pages)
  setPage(rowData)
  console.log('api page sir ',page)
},[rowData]);


  const ClearPage = () => {// clear form field 
    setPage({pageName: '',title: '',description: '',id:'',parentPage: '',userId: '',img: '',imgType: '',displayImg:'',imgUrl:'',});
  };

  
  
  const convertFile = (files) => {
    if (files && files.length > 0 && files[0] instanceof File) { // Check if files is a valid file object
      const fileRef = files[0];
      const fileName = fileRef.name; // Get the file name
      const fileType = fileRef.type;
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev) => {
        const base64Data = `data:${fileType};base64,${btoa(ev.target.result)}`;
        const displayImg = `data:${fileType};base64,${btoa(ev.target.result)}`;
        setPage(prevPage => ({
          ...prevPage,
          img: base64Data,
          displayImg: btoa(ev.target.result),
          imgType: fileType,
          imgUrl: fileName, // Add the fileName to the Page state
        }));
      };
    }
  };
  
  const handleImgChange = (event) => {
    const { name, value, files } = event.target;
    console.log('event target name:', event.target.name);
    console.log('event target files:', event.target.files);
    if (name === "img") {convertFile(files);
    } else {setPage(prevPage => ({...prevPage,[name]: value,})); }
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setPage(prevPage => {
      const updatedPage = { ...prevPage };
      if (name === "pageName") {
        updatedPage.pageName = value;  // Update page value regardless of match
      } else if (name === "title") {
        updatedPage.title = value;   // Update title field
      } else if (name === "description") {
        updatedPage.description = value;     // Update content field
      } else if (name === "parentPage") {
        const selectedPage = parent.find((page) => page.parentPage === value);// Find the selected page and update pageId if found
        if (selectedPage) {updatedPage.parentId = selectedPage.id;
        } else { updatedPage.pageId = ''; }    // If no selected page is found, reset pageId
      }
      return updatedPage;
    });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Required field checks
    console.log('description here sir ', value)
    if (!page.title || !page.pageName || !value) {
      toast.error('Please fill in required fields: title, content, and page');
      return; // Exit function if required fields are missing
    }
    setIsLoading(true);
    const pagePhotos = [{ // Construct PagePhotos array
      file_name: page.imgUrl && page.imgUrl.trim() !== '' ? `${page.pageName}.${page.imgUrl?.split('.').pop() || ''}` 
      : page.imgUrl,
      photo: { data: page.displayImg, contentType: page.imgType},
      // Add any other necessary properties here
    }];
  
    
    
    console.log('here in get sir',page);
  
    try {
      const response = await fetch(`https://www.eldsmw.org:5000/api/pages`, {
  
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`
        },
        body: JSON.stringify({
          id:page.id,
          name: page.pageName,
          title: page.title,
          description: value,
          //img: Page.img,
          parent_id: page.parentId === undefined || page.parentId === null ? 0 : page.parentId,
          //user_id: userData.id,
          pagePhotos: pagePhotos
        })
      });


      
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      toast.success('Page submitted successfully!');
     
      // Return data if needed
      return {
        data
      };
    } catch (err) {
      console.error('Error adding Page:', err);
      toast.success('Page updated  successfully!');
      //throw new Error('Failed to add Page');
    } finally {
      setUpdateCount(updateCount+1);
      setIsLoading(false);
    }
  };
 
  
  const photoUrl = (page && page.displayImg)
  ? `data:${page.imgType};base64,${page.displayImg}`
  : page.imgUrl;
  //: "https:/www.eldsmw.org:5000/api/photos/home.jpg";

console.log('page url  at this point ',page);
  return (
    <div className="">
      <div className="flex">
      <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Page</h2>
  
      </div>
  
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full max-w-full" style={{ width: "500px" }}>
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )}    
       
        {/* <form className={`bg-${bgColor} shadow-md rounded px-8 pt-6 mb-4`}> */}
        <form className={` shadow-md rounded px-8 pt-4 mb-2`}>
            <div className="relative z-0 mb-8 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="pageName"
                    onChange={(event) => handleTextChange(event)}
                    value={page ? page.pageName : ''}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Page Name
                </label>
            </div>

            <div className="relative z-0 mb-8 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="title"
                    onChange={(event) => handleTextChange(event)}
                    value={page ? page.title : ''}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Page Title
                </label>
            </div>

            
            <div className="relative z-0 mb-8 w-full group">
                <ReactQuill 
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                    appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    theme="snow" 
                    style={{ height: "250px" }} 
                    value={value}
                    onChange={setValue} // Pass a function that updates the state with the new content

                />
                <label htmlFor="content" className="absolute text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 
                    peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Page description
                </label>
            </div>
        
            <div className="relative z-0 mb-3 w-full group">
              <input
                type="text"
                id="parentPage"
                name="parentPage"
                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                list="pagesList" // Link the input to the datalist
                onChange={(event) => handleTextChange(event)}
                value={page ? page.parentPage : ''}
              />
              {/* Use the datalist element for autocomplete */}
              <datalist id="pagesList">
                {parent.map((page) => (
                  <option value={page.parentName} />
                ))}
              </datalist>
              <label
                htmlFor="page"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Parent Page
              </label>
            </div>

            <div className="relative z-0 mb-3 w-full group flex items-center">
                <input
                className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                type="file"
                accept="image/*"
                name='img'
                onChange={(event) => handleImgChange(event)}
                />

                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Upload picture</label>
                <div>
                  {photoUrl && ( // Use a single conditional check for efficiency
                    <img
                      className="rounded-full h-24 w-24"
                      src={photoUrl}
                      alt="Page image"
                    />
                  )}
                </div>
            </div>
        <a
          className="mt-3 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          href="#"
        >   
        </a>
      </form>
      <div className="mb-4 ">
<button
  className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
  onClick={handleSubmit}
  
>
  Save
</button>         
        </div>
    </div>
    </div>
  
  )
}

export default EditPage
