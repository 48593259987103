//home.jsx
import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../../contexts/AppContext';
import axios from 'axios';
// import '../../../app.css';
// import Cards from '../../Cards';
import HeroSection from '../../components/Herosection';
import Programs from '../../components/Programs';
import Newsletter from '../../components/Newsletter';
import Testimonial from '../../components/testimonial/Testimonial';
//import Values from '../../components/Values';
import FrontNews from "../../components/frontnews/FrontNews";
// import Newsletter from "../../components/newsletter/Newsletter";
import Footer from '../../components/Footer';

function Home() {
  const { userData,setUserData } = useContext(AppContext);
  // const [projects, setProjects] = useState([]);
  const [page, setPage] = useState([]);
  useEffect(() => {// Check if userRef is defined before calling focus
    setUserData(prevUser => {
      const updatedUser = { ...prevUser };
      updatedUser.isLoggedIn= false;
      return updatedUser;
  })
}, []); // Empty dependency array ensures it runs only on mount

useEffect(() => {
  fetchPage(); // Call the async function within useEffect
  // console.log('projects',projects)
  //fetchArticles(); // Call the async function within useEffect
}, []);

async function fetchPage() {
   
  const config = {
    // headers: { Authorization: `Bearer ${userData.token}` },
    params: { name:'home'}, // query parameters 
  };
  try {
      const response = await axios.get('https://www.eldsmw.org:5000/api/pages', config);
      const data = response.data;
     // console.table('page API Response sir :', data);
      const page= data[0];
      const pageArticles = data[0].pageArticles; // Assuming there's only one object in the array
      // Set the extracted pageArticles to the projects state
      //setProjects(pageArticles);
      setPage(page)
  } catch (error) {
      console.error('Error fetching data:', error);
      return { count: 0, result: null, error: 'Failed to fetch data. Please try again later.', };
  }
}

  

  const photoUrl = page.pagePhotos?.[0]?.photoUrl;
 // console.log('pages heres aresir varoiable sir',photoUrl)

  return (
    <>
 
       <HeroSection title={page.title}  description={page.description} imgUrl={photoUrl}/> 
        {/* <Cards />  */}
        {/* <Programs/> */}
        <FrontNews articles={page?.pageArticles || []} />
        {/* <Values/>  */}
        <Newsletter/>
        
        <Testimonial/>
        <Footer/> 
    </>
  );
}

export default Home;
