import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';

const EditTestimonial = ({  data }) => {
  const [testimonial, setTestimonial] = useState({});
  const [isLoading,setIsLoading]=useState(false);
  const { userData ,updateCount,setUpdateCount} = useContext(AppContext);

  useEffect(() => {
    setTestimonial(data) ;
  }, [data]);

  const convertFile = (files) => {
    if (files && files.length > 0 && files[0] instanceof File) { // Check if files is a valid file object
      const fileRef = files[0];
      const fileName = fileRef.name; // Get the file name
      const fileType = fileRef.type;
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev) => {
        const base64Data = `data:${fileType};base64,${btoa(ev.target.result)}`;
        const displayImg = `data:${fileType};base64,${btoa(ev.target.result)}`;
        setTestimonial(prevTestimonial => ({
          ...prevTestimonial,
          img: base64Data,
          displayImg: btoa(ev.target.result),
          imgType: fileType,
          imgUrl: fileName, // Add the fileName to the Page state
        }));
      };
    }
  };
  
  


  const handleImgChange = (event) => {
    const { name, value, files } = event.target;
    console.log('event target name:', event.target.name);
    console.log('event target files:', event.target.files);
    if (name === "img") {convertFile(files);
    } else {setTestimonial(prevTestimonial => ({...prevTestimonial,[name]: value,}));}
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setTestimonial(prevTestimonial => {
      const updatedTestimonial = { ...prevTestimonial };
      updatedTestimonial[name] = value;
      return updatedTestimonial; // Return updatedPage to update the state
    });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Required field checks
  
    if (!testimonial.name) {toast.error('Please enter name ');return;  }
    if (!testimonial.occupation) {toast.error('Please enter occupation ');return;  }
    if (!testimonial.address) {toast.error('Please enter address ');return;  }
    if (!testimonial.content) {toast.error('Please enter message  ');return;  }
    setIsLoading(true);
    try {
      const response = await fetch('https://www.eldsmw.org:5000/api/testimonials', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`
        },
        body: JSON.stringify({
          id:testimonial.id,
          name: testimonial.name,
          occupation: testimonial.occupation,
          content: testimonial.content,
          address: testimonial.district,
          photo_file_name: testimonial.imgUrl !== null ? testimonial.imgUrl : null,
          photo: {
            data: testimonial.displayImg !== null ? testimonial.displayImg : null,
            contentType: testimonial.imgType !== null ? testimonial.imgType : null
          }
        })
      });
      if (!response.ok) { throw new Error(`HTTP error! Status: ${response.status}`);}
  
      const data = await response.json();
      toast.success('Testimoial submitted successfully!');
      return {data};
    } catch (err) {
      console.error('Error adding Page:', err);
      throw new Error('Failed to edit Testimonial');
    } finally {
      setIsLoading(false);
     setUpdateCount(updateCount+1);
    }
  };
  console.log('testimonial at this time',testimonial);
  const photoUrl = (testimonial && testimonial.displayImg)
  ? `data:${testimonial.imgType};base64,${testimonial.displayImg}`
  : testimonial.photo_url;

  return (
    <div className="">
      <div className="flex">
        <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Testimonial</h2>

      </div>
  
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full max-w-full" style={{ width: "500px" }}>
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )}    
       
        {/* <form className={`bg-${bgColor} shadow-md rounded px-8 pt-6 mb-4`}> */}
        <form className={` shadow-md rounded px-8 pt-4 mb-2`}>
            <div className="relative z-0 mb-8 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="name"
                    onChange={(event) => handleTextChange(event)}
                    value={testimonial.name}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Testifier Name
                </label>
            </div>

            <div className="relative z-0 mb-8 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="occupation"
                    onChange={(event) => handleTextChange(event)}
                    value={testimonial.occupation}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Occupation
                </label>
            </div>

            <div className="relative z-0 mb-3 w-full group">
              <input
                type="text"
                name="address"
                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                // list="parentsList" // Link the input to the datalist
                onChange={(event) => handleTextChange(event)}
                value={testimonial.address}
              />
     
               
              <label
                htmlFor="address"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Address (District)
              </label>
            </div>

            <div className="relative z-0 mb-8 w-full group">
                <ReactQuill 
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                    appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    theme="snow" 
                    style={{ height: "250px" }} 
                    value={testimonial?.content} 
                    onChange={(newValue) => handleTextChange({ target: { name: 'content', value: newValue } })} 
                />
                <label htmlFor="content" className="absolute text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 
                    peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Testimony
                </label>
            </div>
        
            

            <div className="relative z-0 mb-3 w-full group flex items-center">
                <input
                className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                type="file"
                accept="image/*"
                name='img'
                onChange={(event) => handleImgChange(event)}
                />

                <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Testifier Profile Photo</label>
                {photoUrl && (
                 <img
                 className="rounded-full h-24 w-24"
                 src={photoUrl}
                 alt="Testifier"
               />
                )}
            </div>
        <a
          className="mt-3 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          href="#"
        >   
        </a>
      </form>
      <div className="mb-4 ">
<button
  className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
  onClick={handleSubmit}
  
>
  Save
</button>         
        </div>
    </div>
    </div>
  
  )
}

export default EditTestimonial;
