

import React, { useState, useEffect, useContext  } from "react";
import axios, { all } from 'axios';
import './signin.css'
import Login from '../../components/Login.jsx';//

const Signin = () => {
  // const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const [page, setPage] = useState([]);
  useEffect(() => {
    fetchPage(); 
  }, []);

  async function fetchPage() {
    const config = {
      params: { name:'signin'}, // query parameters 
    };
    try {
      const response = await axios.get('https://www.eldsmw.org:5000/api/pages', config);
      const data = response.data;
      // console.table('signin page API Response sir :', data);
      setPage(data[0])
    } catch (error) {
      console.error('Error fetching data:', error);
      return { count: 0, result: null, error: 'Failed to fetch data. Please try again later.', };
    }
  }
  const imgUrl = page.pagePhotos?.[0]?.photoUrl || null;

  return (
    <>
      <section className="login">
        <div className="container login__container">
          <div className="login__wrapper">
            <Login  imgUrl={imgUrl}/>
          </div>
        </div>
      </section> 
    </>
  )
}

export default Signin
