import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';

const AddUser = ({ FetchUsers }) => {
const [user, setUser] = useState([{ username: '',  password: '', role:'', img:'',imgType:'',displayImg:'',imgUrl:'',}]);
const [value, setValue] = useState('');
const [role, setRole] = useState('');
const [roles, setRoles] = useState([]);
const [isLoading,setIsLoading]=useState(false);
const { userData } = useContext(AppContext);




  

  const ClearUser = () => {// clear form field 
    setUser({username: '',password: '',role: '',img: '',imgType: '',displayImg:'',imgUrl:'',});
  };

  
  const fetchRoles= async () => {
   // console.log('here sir in fetch users ',userData.token)
    const config = { headers: { Authorization: `Bearer ${userData.token}` },};
    try {
      const response = await axios.get('https://www.eldsmw.org:5000/api/users',config);
      const data = response.data;

     console.table('API Response:', data); // Log the response
      setRole(data)
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch data. Please try again later.',
      };
    }
  };
  
  const convertFile = (files) => {
    if (files && files.length > 0 && files[0] instanceof File) { // Check if files is a valid file object
      const fileRef = files[0];
      const fileName = fileRef.name; // Get the file name
      const fileType = fileRef.type;
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev) => {
        const base64Data = `data:${fileType};base64,${btoa(ev.target.result)}`;
        const displayImg = `data:${fileType};base64,${btoa(ev.target.result)}`;
        setRole(prevuser => ({
          ...prevuser,
          img: base64Data,
          displayImg: btoa(ev.target.result),
          imgType: fileType,
          imgUrl: fileName, // Add the fileName to the user state
        }));
      };
    }
  };
  
  


  const handleImgChange = (event) => {
    const { name, value, files } = event.target;
    console.log('event target name:', event.target.name);
    console.log('event target files:', event.target.files);
    if (name === "img") {
      convertFile(files);
    } else {
      setUser(prevuser => ({
        ...prevuser,
        [name]: value,
      }));
    }
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setUser(prevuser => {
      const updateduser = { ...prevuser };
      if (name === "name") {
        // Update user value regardless of match
        updateduser.name = value;
  
        
      } else if (name === "title") {
        // Update title field
        updateduser.title = value;
      } else if (name === "description") {
        // Update content field
        updateduser.description = value;
      } else if (name === "parent") {
        console.log('here sir  parent',value)
        // Find the selected user and update userId if found
        const selecteduser = roles.find((user) => user.name === value);
        console.log('selected users ', selecteduser);
        if (selecteduser) {
          updateduser.roleId = selecteduser.id;
        } else {
          // If no selected user is found, reset userId
          updateduser.userId = '';
        }
        // Update publishDate field
        updateduser.publishDate = value;
      } else if (name === "img") {
        // Handle image file upload
        convertFile(value); // You may need to adjust this part based on your implementation
      }
      
      console.log('in change sir',updateduser); // Log updateduser to check all fields
      return updateduser; // Return updateduser to update the state
    });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Required field checks
    console.log('description', value)
    if (!user.title || !user.name || !value) {
      toast.error('Please fill in required fields: title, content, and user');
      return; // Exit function if required fields are missing
    }

    setIsLoading(true);
    // Construct userPhotos array
 
    console.log('here in post parent ',user.parentId);
  
    try {
      const response = await fetch('https://www.eldsmw.org:5000/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`
        },
        body: JSON.stringify({
          name: user.name,
          title: user.title,
          description: value,
          link:user.name.toLowerCase(),
          parent_id: user.parentId === undefined || user.parentId === null ? 0 : user.parentId,
          file_name: user.imgUrl,
          data: user.img,
          photo:user.imgType
        })
      });
     
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      toast.success('user submitted successfully!');
      FetchUsers();
   
      // Return data if needed
      return {
        data
      };
    } catch (err) {
      console.error('Error adding user:', err);
      throw new Error('Failed to add user');
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="">
      <div className="flex">
        <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New user</h2>
     
      </div>
  
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full max-w-full" style={{ width: "500px" }}>
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )}    
       
        {/* <form className={`bg-${bgColor} shadow-md rounded px-8 pt-6 mb-4`}> */}
        <form className={` shadow-md rounded px-8 pt-4 mb-2`}>
            <div className="relative z-0 mb-8 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="name"
                    onChange={(event) => handleTextChange(event)}
                    value={user.username}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">user Name
                </label>
            </div>

            <div className="relative z-0 mb-8 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="title"
                    onChange={(event) => handleTextChange(event)}
                    value={user.password}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">user Title
                </label>
            </div>

            <div className="relative z-0 mb-8 w-full group">
                <ReactQuill 
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                    appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    theme="snow" 
                    style={{ height: "250px" }} 
                    value={value} 
                    onChange={setValue} 
                />
                <label htmlFor="content" className="absolute text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 
                    peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">user description
                </label>
            </div>
        
            <div className="relative z-0 mb-3 w-full group">
              <input
                type="text"
                id="parent"
                name="parent"
                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                list="usersList" // Link the input to the datalist
                onChange={(event) => handleTextChange(event)}
                value={user.role}
              />
              {/* Use the datalist element for autocomplete */}
              <datalist id="usersList">
                {roles.map((role) => (
                  <option value={role.name} />
                ))}
              </datalist>
              <label
                htmlFor="user"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Parent user
              </label>
            </div>

            <div className="relative z-0 mb-3 w-full group flex items-center">
                <input
                className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                type="file"
                accept="image/*"
                name='img'
                onChange={(event) => handleImgChange(event)}
                />

                <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Upload picture</label>
                {user.img && (
                <img
                    className="rounded-full h-24 w-24"
                    src={`data:${user.imgType};base64,${user.displayImg}`}
                    alt="user image"
                />
                )}
            </div>
        <a
          className="mt-3 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          href="#"
        >   
        </a>
      </form>
      <div className="mb-4 ">
<button
  className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
  onClick={handleSubmit}
  
>
  Save
</button>         
        </div>
    </div>
    </div>
  
  )
}

export default AddUser
