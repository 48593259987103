import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';
import moment from 'moment';

const EditArticle = ({ rowData}) => {
  const [article, setArticle] = useState({});
  const [value, setValue] = useState('');
  const [isLoading,setIsLoading]=useState(false);
  const { userData,  pages, updateCount, setUpdateCount } = useContext(AppContext);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [base64Data, setBase64Data] = useState('');
  const [displayImg, setDisplayImg] = useState('');

  useEffect(() => {
    setArticle(rowData);
   // setValue(rowData.content)
    //fetchArticle();
  }, [rowData]);

  const ClearArticle = () => {// clear form field 
   // setArticle({id: '',publishDate: '',title: '',content: '',page:'',pageId: '',userId: '',img: '',imgType: '',displayImg:''});
  };
  
  const convertFile = (files) => {
    if (files && files.length > 0 && files[0] instanceof File) { // Check if files is a valid file object
      const fileRef = files[0];
      const fileName = fileRef.name; // Get the file name
      const fileType = fileRef.type;
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev) => {
        const base64Data = `data:${fileType};base64,${btoa(ev.target.result)}`;
        const displayImg = `data:${fileType};base64,${btoa(ev.target.result)}`;

        //setBase64(base64);
        setFileType(fileType);
        setFileName(fileName);
        setBase64Data(btoa(ev.target.result))
        // setArticle(prevArticle => ({
        //   ...prevArticle,
        //   img: base64Data,
        //   displayImg: btoa(ev.target.result),
        //   imgType: fileType,
        //   imgUrl: fileName, // Add the fileName to the article state
        // }));
      };
    }
    
  };
  
  


  const handleImgChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "img") {

      convertFile(files);
     
    } else {
      setArticle(prevArticle => ({
        ...prevArticle,
        [name]: value,
      }));
    }
  };


  const handleTextChange = (event) => {
    const { name, value } = event.target;
  
    setArticle(prevArticle => {
      const updatedArticle = { ...prevArticle };
      if (name === "page") {

        // Update page value regardless of match
       // updatedArticle.page = value;
        updatedArticle.pages.name= value;
        // Find the selected page and update pageId if found
        const selectedPage = pages.find((page) => page.name === value);
        if (selectedPage) {
          updatedArticle.page_id = selectedPage.id;
       
        } else {
          // If no selected page is found, reset pageId
          updatedArticle.pageId = '';
        }
      } else if (name === "title") {
        // Update title field
        updatedArticle.title = value;
      } else if (name === "content") {
        updatedArticle.content = value;
      } else if (name === "publishDate") {
        // Update publishDate field
        updatedArticle.publishDate = value;
      } else if (name === "img") {
        // Handle image file upload
        convertFile(value); 
      } else {updatedArticle[name] = value;}
      
       return updatedArticle; // Return updatedArticle to update the state
    });
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    // Required field checks
    // if (!article.title || !value  ) {
    //   toast.error('Please fill in required fields: title, content, and page');
    //   return; // Exit function if required fields are missing
    // }

    setIsLoading(true);
    // Construct articlePhotos array
    // console.log(article.imgUrl);

    //  const articlesPhotos = { // Construct PagePhotos array
    //    file_name: article.file_name && article.displayImg.trim() !== '' ? `${article.imgUrl}.${article.imgUrl?.split('.').pop() || ''}` 
    //   : null,
    //    photo: {
    //      data: article.displayImg !== null ? article.displayImg : null,
    //     contentType: article.imgType !== null ? article.imgType : null
    //    }
      
    // //   // Add any other necessary properties here
    //  };

     const articlePhotos = [{// Construct PagePhotos array
      file_name:  fileName,
      photo: {
        data:base64Data,
       contentType: fileType 
      }
     
   //   // Add any other necessary properties here
    }];
    
    console.log('file name before saving ',fileName);
    console.log('file type ',fileType);
    console.log('base64Data ',base64Data);
    console.log('title ',article.title);
    console.log('content ',article.content);
    console.log('id ',article.id);
    console.log('publish_date ',article.publish_date);
    // var timestamp = article.publish_date.getTime();
    //var date = article.publish_date;
    //var timestamp=date.getTime();


    
  
    try {

      const response = await fetch('https://www.eldsmw.org:5000/api/articles', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`
        },

        body: JSON.stringify({
          id:article.id,
          title: article.title,
          content: article.content,
          // publish_date: article.publish_date,
        
          page_id: article.page_id,
          articlePhotos:articlePhotos
          // file_name: fileName,
          // photo: {data: base64Data ,contentType: fileType }
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      toast.success('Article submitted successfully!');
      
   
      // Return data if needed
      return {
        data
      };
    } catch (err) {
      console.error('Error adding article:', err);
      toast.error('Failed to add article. Please try again later.');
      // Handle the error gracefully without breaking the code execution
     // throw new Error('Failed to add article');
    } finally {
      setUpdateCount(updateCount+1)
      setIsLoading(false);
    }
  };
  console.log('article  at this time',article)

  console.log('article photo at this time',fileType, base64Data)
  //const photoUrl="";
  const photoUrl = article?.articlePhotos?.[0]?.photoUrl
  ? article?.articlePhotos[0]?.photoUrl
  : `data:${fileType};base64,${base64Data}`;
  
  console.log('article photo at this time',photoUrl)

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

   console.log('pages  at this point ' ,pages);

  //console.log('photpo at this point ' ,article.articlePhotos[0].photoUrl  )
  return (
    <div className="">
      <div className="flex">
      <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">
        Edit Article</h2>
      </div>
  
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full max-w-full" style={{ width: "500px" }}>
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )}    
       
        {/* <form className={`bg-${bgColor} shadow-md rounded px-8 pt-6 mb-4`}> */}
        <form className={` shadow-md rounded px-8 pt-4 mb-2`}>
          <div className="relative z-0 mb-8 w-full group">
            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
              text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
              dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
              focus:ring-0 focus:border-blue-600 peer" 
              placeholder=" "  
              name="title"
              onChange={(event) => handleTextChange(event)}
              value={article?.title}/>
            <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
              transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
              peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
              peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Article Title</label>
          </div>

          <div className="relative z-0 mb-8 w-full group">
            <ReactQuill 
              className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              theme="snow" 
              style={{ height: "250px" }} 
              value={article?.content} 
              // onChange={setValue} 
              onChange={(newValue) => handleTextChange({ target: { name: 'content', value: newValue } })} 
            />

            <label htmlFor="content" className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Article Content</label>
          </div>

          <div className="relative z-0 mb-3 w-full group">
            <input
              type="date"
              name="publish_date"
              className="block py-2 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              onChange={(event) => handleTextChange(event)}
              value={formatDate(article?.publish_date)}
            />
            <label
              htmlFor="publishDate"
              className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Publish Date.
            </label>
          </div>
          <div className="relative z-0 mb-3 w-full group">
            <input
              type="text"
              name="page"
              className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              list="pagesList" // Link the input to the datalist
              onChange={(event) => handleTextChange(event)}
              value={article?.pages?.name}
            />
            <datalist id="pagesList"> {/* Use the datalist element for autocomplete */}
            {pages && pages.map((page) => (
                <option key={page.id} value={page.name} />
            ))}

            </datalist>
            <label
              htmlFor="page"
              className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Target Page
            </label>
          </div>
          <div className="relative z-0 mb-3 w-full group flex items-center">
            <input
              className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
              type="file"
              accept="image/*"
              name='img'
              onChange={(event) => handleImgChange(event)}
            />
            <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Upload picture</label>
            {/* {article.articleImgs && article.articleImgs.map((img, index) => ( */}
            <img
              //key={index} // You should assign a unique key to each image element
              className="rounded-full h-24 w-24"
              src={photoUrl}
              //alt={`article ${index}`} // You can add index to differentiate alt text
            />
            {/* ))} */}
          </div>
          <a
            className="mt-3 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
            href="#"
          >   
          </a>
        </form>
        <div className="mb-4 ">
          <button
            className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
            onClick={handleSubmit}
          >
            Save
          </button>         
        </div>
      </div>
    </div>
  )
}

export default EditArticle
