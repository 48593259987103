import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';

const AddArticleForm = () => {
  const [article, setArticle] = useState([{ publishDate: '',  title: '', content:'',page:'', pageId:'' ,userId:0 ,img:'',imgType:'',displayImg:'',imgUrl:'',}]);
  const [value, setValue] = useState('');
  const [isLoading,setIsLoading]=useState(false);
  const { userData, pages, updateCount, setUpdateCount } = useContext(AppContext);

  const ClearArticle = () => {// clear form field 
    setArticle({publishDate: '',title: '',content: '',page:'',pageId: '',userId: '',img: '',imgType: '',displayImg:'',imgUrl:'',});
  };

 
  
  const convertFile = (files) => {
    if (files && files.length > 0 && files[0] instanceof File) { // Check if files is a valid file object
      const fileRef = files[0];
      const fileName = fileRef.name; // Get the file name
      const fileType = fileRef.type;
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev) => {
        const base64Data = `data:${fileType};base64,${btoa(ev.target.result)}`;
        const displayImg = `data:${fileType};base64,${btoa(ev.target.result)}`;
        setArticle(prevArticle => ({
          ...prevArticle,
          img: base64Data,
          displayImg: btoa(ev.target.result),
          imgType: fileType,
          imgUrl: fileName, // Add the fileName to the article state
        }));
      };
    }
  };
  
  


  const handleImgChange = (event) => {
    const { name, value, files } = event.target;
    console.log('event target name:', event.target.name);
    console.log('event target files:', event.target.files);
    if (name === "img") {

      convertFile(files);
     
    } else {
      setArticle(prevArticle => ({
        ...prevArticle,
        [name]: value,
      }));
    }
  };


  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setArticle(prevArticle => {
      const updatedArticle = { ...prevArticle };
      if (name === "page") {
        updatedArticle.page = value;// Update page value regardless of match
        // Find the selected page and update pageId if found
        const selectedPage = pages.find((page) => page.name === value);
        console.log('selected pages from api sir ', selectedPage);
        if (selectedPage) {updatedArticle.pageId = selectedPage.id;
        } else {updatedArticle.pageId = '';} // If no selected page is found, reset pageId
      } else if (name === "title") {
        // Update title field
        updatedArticle.title = value;
      } else if (name === "content") {
        // Update content field
        updatedArticle.content = value;
      } else if (name === "publishDate") {
        // Update publishDate field
        updatedArticle.publishDate = value;
      } else if (name === "img") {
        // Handle image file upload
        convertFile(value); // You may need to adjust this part based on your implementation
      }
      
      console.log('in change sir',updatedArticle); // Log updatedArticle to check all fields
      return updatedArticle; // Return updatedArticle to update the state
    });
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    console.log('article to save sir ',article)
    // Required field checks
    if (!article.title || !value  ) {
      toast.error('Please fill in required fields: title, content, and page');
      return; // Exit function if required fields are missing
    }

    setIsLoading(true);
    // Construct articlePhotos array
    const articlePhotos = [{
      file_name: article.imgUrl,
      photo: { data: article.displayImg, contentType: article.imgType},
      // Add any other necessary properties here
    }];
  
    try {
      const response = await fetch('https://www.eldsmw.org:5000/api/articles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`
        },
        body: JSON.stringify({
          title: article.title,
          content: value,
          //img: article.img,
          publish_date: article.publishDate,
          page_id: article.pageId,
          //user_id: userData.id,
          articlePhotos: articlePhotos
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      toast.success('Article submitted successfully!');
   
   
      // Return data if needed
      return {
        data
      };
    } catch (err) {
      console.error('Error adding article:', err);
      toast.error('Failed to add article. Please try again later.');
      // Handle the error gracefully without breaking the code execution
     // throw new Error('Failed to add article');
    } finally {
      setUpdateCount(updateCount+1);
      setIsLoading(false);
    }
  };
  

  return (
    <div className="">
      <div className="flex">
      <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Article</h2>
      </div>
  
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full max-w-full" style={{ width: "500px" }}>
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )}    
       
        {/* <form className={`bg-${bgColor} shadow-md rounded px-8 pt-6 mb-4`}> */}
        <form className={` shadow-md rounded px-8 pt-4 mb-2`}>
          <div className="relative z-0 mb-8 w-full group">
            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
            text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
              dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
              focus:ring-0 focus:border-blue-600 peer" 
              placeholder=" "  
              name="title"
              onChange={(event) => handleTextChange(event)}
              value={article.title}/>
            <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
            transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
            peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Article Title</label>
          </div>

          <div className="relative z-0 mb-8 w-full group">
     
      <ReactQuill 
      className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
       
      theme="snow" 
      style={{ height: "250px" }} 
      value={value} 
      onChange={setValue} 
      />
      <label htmlFor="content" className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Article Content</label>
    </div>



          <div className="relative z-0 mb-3 w-full group">
              <input
                type="date"
                id="publishDate"
                name="publishDate"
                className="block py-2 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
             
                onChange={(event) => handleTextChange(event)}
                value={article.publishDate}
              />
              <label
                htmlFor="publishDate"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Publish Date.
              </label>
            </div>
         
  
         
  
  
            <div className="relative z-0 mb-3 w-full group">
              <input
                type="text"
                id="page"
                name="page"
                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                list="pagesList" // Link the input to the datalist
                onChange={(event) => handleTextChange(event)}
                value={article.page}
              />
              {/* Use the datalist element for autocomplete */}
              <datalist id="pagesList">
                {pages && pages.map((page) => (
                  <option value={page.name} />
                ))}
              </datalist>
              <label
                htmlFor="page"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Target Page
              </label>
            </div>

            <div className="relative z-0 mb-3 w-full group flex items-center">



<input
  className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
  type="file"
  accept="image/*"
  name='img'
  onChange={(event) => handleImgChange(event)}
/>

<label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Upload picture</label>
{article.img && (
  <img
    className="rounded-full h-24 w-24"
    src={`data:${article.imgType};base64,${article.displayImg}`}
    alt="article image"
  />
)}



</div>


         

         
          
        <a
          className="mt-3 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          href="#"
        >   
        </a>
      </form>
      <div className="mb-4 ">
<button
  className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
  onClick={handleSubmit}
  
>
  Save
</button>         
        </div>
    </div>
    </div>
  
  )
}

export default AddArticleForm
