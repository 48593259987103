import React, { useState, useRef, useContext } from "react";
import AppContext from '../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Audio } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';
import { FiUpload } from "react-icons/fi";
import axios from 'axios';
import { ImageConfig } from '../../config/ImageConfig'; 
import uploadImg from '../../images/cloud-upload-regular-240.png';

const FileUpload = (props) => {
    const [capitalizedName, setCapitalizedName] = useState('');
    const [hover, setHover] = useState(false);
    const { userData, newsletters, setNewsletters,updateCount, setUpdateCount } = useContext(AppContext);
    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            const updatedList = [...fileList, newFile];
            setFileList(updatedList);
            props.onFileChange(updatedList);
        }
    }

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    }

    const handleUpload = async () => {
        console.log("Uploading files:", fileList);
        setIsLoading(true); // Start loading
        try {
            if (fileList.length === 0) {
                toast.error('No files to upload. Please select a file.');
                setIsLoading(false); // End loading
                return;
            }
            const filesData = [];
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i];
                if (file instanceof File) {
                    const reader = new FileReader();
                    reader.readAsBinaryString(file);
                    await new Promise((resolve, reject) => {
                        reader.onload = (ev) => {
                            const base64Data = btoa(ev.target.result);
                            const fileType = file.type;
                            const fileName = file.name;
                            const fileData = {
                                file_name: fileName,
                                file: {
                                    data: base64Data !== null ? base64Data : null,
                                    contentType: fileType !== null ? fileType : null
                                }
                            };
                            filesData.push(fileData);
                            resolve();
                        };
                        reader.onerror = reject;
                    });
                }
            }
    
            if (filesData.length > 0) {
                console.log('files data',filesData.length)
                const response = await fetch('https://www.eldsmw.org:5000/api/newsletters', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userData.token}`
                    },
                    body: JSON.stringify({
                        file_name: filesData[0].file_name,
                        file: filesData[0].file
                    })
                });
                console.log('api response ',response)
                if (response.ok) {
                    setSuccess(true);
                    setUpdateCount(updateCount+1);
                    console.log('Upload successful:', await response.json());
                    toast.success('Upload successful!');
                } else { 
                    toast.error('Failed to upload. Try again later ',response);
                    throw new Error('Failed to upload');
                }
            } 
            
        } catch (error) {
            console.error('Upload failed:', error);
            if (error.message === 'No files to upload') {
                toast.error('No files to upload. Please select file');
            } else {
                toast.error('Upload failed. Please try again.');
            }
        } finally {
            setIsLoading(false); // End loading
        }
    }


    return (
        <>
            <div className="flex justify-center">
               
            <ToastContainer position="top-center" autoClose={4000} />
                <div className="border border-dotted border-gray-500 inline-flex justify-center"
                    ref={wrapperRef}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    <label htmlFor="file-input" className={`text-center p-8 ${hover ? 'hover' : ''}`}>
                        <div className="flex justify-center">
                            <span className="inline-block cursor-pointer">
                                <img src={uploadImg} alt="Upload File" className="w-6 h-6 hover:cursor-pointer" />
                            </span>
                        </div>
                        <p className='text-gray-700'>Drag & Drop your files here</p>
                    </label>
                    <input
                        id="file-input"
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        value=""
                        onChange={onFileDrop}
                    />
                </div>
            </div>
            {isLoading ? (
                <div className="flex items-center flex-col ">
                    <Audio height="80" width="80" radius="9" color="blue" ariaLabel="three-dots-loading" />
                    <p className="mt-2">Uploading file. Please wait...</p>
                </div>
            ) : (
            <div className='mt-4 p-4'>
               {fileList.length > 0 && !success && (
                    <div className="flex justify-center">
                        {fileList.map((item, index) => (
                            <div key={index} className="mx-1 py-2 px-2 border border-2 border-blue-500 justify-center rounded-lg text-center flex flex-col items-center w-40 h-30">
                                <img
                                    src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']}
                                    alt=""
                                    style={{ width: '50px', height: '50px' }}
                                />
                                <div className="text-gray-500 items-center justify-center">
                                    <p>
                                        {item.name.toLowerCase().length > 20
                                            ? item.name.toLowerCase().substring(0, 17) + '...'
                                            : item.name.toLowerCase()}
                                    </p>
                                    <p>{(item.size / 1024).toFixed(2)} kb</p>
                                    <div className="flex items-center justify-center cursor-pointer hover:cursor-pointer">
                                        <span onClick={() => fileRemove(item)}>
                                            <FaTrash color="#FF0000" size={15} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            )}
            <div className='flex justify-center mt-4 p-4 border-t-2 border-green-900 cursor-pointer hover:cursor-pointer' onClick={handleUpload}>
                
                <FiUpload color='#4169E1' size={30} />
            </div>
            
            
        </>
    );
}

FileUpload.propTypes = {
    onFileChange: PropTypes.func
}

export default FileUpload;
