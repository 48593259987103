import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';


const EditImage = ({imageId,pages,articles}) => {
const [image, setImage] = useState({ caption: '',pageId: '',pageName:'',articleId: '',articleName:'',tags:'',img: '',imgType: '',displayImg:'',imgUrl:'',});
const [value, setValue] = useState('');
const [isLoading,setIsLoading]=useState(false);
const { userData } = useContext(AppContext);

console.log('pages',pages);
console.log('articles',articles);
useEffect(() => {
 //setShowImgActionDialog(false)
    fetchImage(imageId);

},[imageId]);


  const Clearimage = () => {// clear form field 
    setImage({caption: '',pageId: '',pageName:'',articleId: '',articleName:'',tags:'',img: '',imgType: '',displayImg:'',imgUrl:'',});
  };
  
  const fetchImage= async () => {
    const config = {
     // headers: { Authorization: `Bearer ${userData.data.token}` },
     params: { id: imageId }, // Send productId as a query parameter
   };
   try {
       const response = await axios.get('https://www.eldsmw.org:5000/api/images',config);
       const data = response.data;
       //console.table('image sir API Response:', data); // Log the response
       //console.log('image  from api :', data[0].id); // Log the order value  //setOrder(data.result[0][0]);

       const updatedImg = {}; 
       if (data && Array.isArray(data) && data.length > 0) {
        updatedImg.id = data[0].id;
        updatedImg.caption = data[0].caption;
        updatedImg.tags= data[0].tags
        updatedImg.page = data[0].page_id;
        updatedImg.article = data[0].article_id;
        updatedImg.imgUrl = data[0].photoUrl;
        // Access the page name if the "pages" property exists
        if (data[0].pages && data[0].pages.name) {
          updatedImg.pageName = data[0].pages.name; // Store the page name
        } else { updatedImg.pageName = "";  }// Set an empty string if no page name is found
       

        // Access the article name if the "article" property exists
        if (data[0].articles && data[0].articles.title) {
          updatedImg.articleName = data[0].articles.title; // Store the page name
        } else {
          updatedImg.articleName = ""; // Set an empty string if no page name is found
        }



       }


     setImage(updatedImg);

    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch data. Please try again later.',
      };
    }
  };
 
  
  
  
  
  
  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setImage(prevImg => {
      const updatedImg = { ...prevImg };
      if (name === "caption") {
        updatedImg.caption= value;  
      }else if (name === "tags") {
          updatedImg.tags= value;  
      } else if (name === "pageName") {
        updatedImg.pageName= value; 
        const selectedPage = pages.find((page) => page.name === value);// Find the selected image and update imageId if found
        if (selectedPage) {updatedImg.pageId = selectedPage.id;
        } else { updatedImg.pageId = 0; }    // If no selected image is found, reset imageId
      } else if (name === "articleName") {
        updatedImg.articleName= value; 
        const selectedArticle = articles.find((article) => article.title === value);// Find the selected image and update imageId if found
        if (selectedArticle) {updatedImg.articleId = selectedArticle.id;
        } else { updatedImg.articleId = 0; }    // If no selected image is found, reset imageId
      }
      return updatedImg;
    });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
   // Required field checks
   console.log('just befor ddddsave ',image)
    if (!image.caption || !image.tags) {
      toast.error('Please fill in caption and  tags field');
      return; // Exit function if required fields are missing
    }
 
    setIsLoading(true);
    const imgObj = { // Construct imagePhotos array
      //file_name: image.imgUrl?.trim() !== '' ? image.imgUrl : "",
      data: image.displayImg, contentType: image.imgType
      // Add any other necessary properties here
    };
    const tags = image.tags.split(",").map((tag) => tag.trim());
    console.log('just befor dsave ',image)
    try {
      const response = await fetch(`https://www.eldsmw.org:5000/api/images`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`
        },
        body: JSON.stringify({
          id:image.id,
          caption: image.caption,
          tags:tags,
          page_id:image.pageId,
          article_id:image.articleId,

          
        })
      });


      
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      toast.success('image submitted successfully!');
      //fetchImages();
   
      // Return data if needed
      return {
        data
      };
    } catch (err) {
      console.error('Error adding image:', err);
      toast.success('image updated  successfully!');
     // fetchImages();
      //throw new Error('Failed to add image');
    } finally {
      setIsLoading(false);
    } 
  
  };
 
  

console.log('image at this point',image)

  return (
    <div className="">
      <div className="flex">
      <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Image</h2>
  
      </div>
  
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full max-w-full" style={{ width: "500px" }}>
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )}    
       
        {/* <form className={`bg-${bgColor} shadow-md rounded px-8 pt-6 mb-4`}> */}
        <form className={` shadow-md rounded px-8 pt-4 mb-2`}>


        <div className="relative z-0 mb-3 w-full group flex items-center">
               

                 <div>
                  {image.imgUrl && ( // Use a single conditional check for efficiency
                    <img 
                    className="w-full transition duration-300 ease-in-out transform hover:scale-110 click:filter-grayscale" 
                    style={{ width: '400px', height: '300px', position: 'relative' }}
                    src={image.imgUrl} 
                    alt={`Photo ${image.caption}`} 
                   
                  />
                  )}
                </div>
            </div>


            <div className="relative z-0 mb-4 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="caption"
                    onChange={(event) => handleTextChange(event)}
                    value={image ? image.caption : ''}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Caption
                </label>
            </div>

            <div className="relative z-0 mb-4 w-full group">
                {/* <label htmlFor="tagsInput" className="block mb-2">Enter Tags (separated by commas):</label> */}
                <input
                    type="text"
                    id="tags"
                    name="tags"
                    className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer"
                    value={image.tags}
                    onChange={(event) => handleTextChange(event)}
                    placeholder="e.g., tag1, tag2, tag3"
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Enter Tags (separated by commas):
                </label>
            </div>


            <div className="relative z-0 mb-4 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="pageName"
                    list="pagesList" // Link the input to the datalist
                    onChange={(event) => handleTextChange(event)}
                    value={image ? image.pageName : ''}
                   
                />
                {/* Use the datalist element for autocomplete */}
                <datalist id="pagesList">
                  {pages && pages.map((page) => (
                    <option value={page.name} />
                  ))}
                </datalist>
                <label htmlFor="pageName" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Target Page (if any)
                </label>
            </div>
            
            <div className="relative z-0 mb-4 w-full group">
                <input type="text"  id="articleName" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="articleName"
                    list="articlesList" // Link the input to the datalist
                    onChange={(event) => handleTextChange(event)}
                    value={image ? image.articleName: ''}
                />
                {/* Use the datalist element for autocomplete */}
                <datalist id="articlesList">
                  {articles && articles.map((article) => (
                    <option value={article.title} />
                  ))}
                </datalist>
                <label htmlFor="articleName" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Target Article (if any)
                </label>
            </div>

            
            
        
            

        <a
          className="mt-3 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          href="#"
        >   
        </a>
      </form>
      <div className="mb-4 ">
<button
  className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
  onClick={handleSubmit}
  
>
  Save
</button>         
        </div>
    </div>
    </div>
  
  )
}

export default EditImage
