import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../contexts/AppContext';
import { MdClose, MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Audio } from 'react-loader-spinner';
import Gallery from '../../components/gallery/Gallery';
import Footer from '../../components/Footer';
import axios from 'axios';
import './photogallery.css';

const PhotoGallery = () => {
  const [images, setImages] = useState([]);
  const { userData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(true); // Open modal by default

  useEffect(() => {
    fetchImages();
  }, []); // Empty dependency array ensures it runs only on mount

  const fetchImages = async () => {
    try {
      const config = { headers: { Authorization: `Bearer ${userData.token}` } };
      const response = await axios.get('https://www.eldsmw.org:5000/api/images', config);
      const data = response.data;
      setImages(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching or error
    }
  };

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous Image (using modulo operator for circular navigation)
  const prevSlide = () => {
    setSlideNumber((slideNumber - 1 + images.length) % images.length);
  };

  // Next Image (using modulo operator for circular navigation)
  const nextSlide = () => {
    setSlideNumber((slideNumber + 1) % images.length);
  };

  console.log('images', images);

  return (
    <div>
      {isLoading ? (
        <div className="loading-container">
          {/* Your loading indicator element here (e.g., spinner, text) */}
          <Audio />
          <p>Loading Images...</p>
        </div>
      ) : (
        <>
         {openModal && (
  <div className='sliderWrap'>
    {/* <MdClose size={30} className='btnClose' onClick={handleCloseModal} /> */}
    <MdChevronLeft size={70} className='btnPrev' onClick={prevSlide} />
    <MdChevronRight size={70}  className='btnNext' onClick={nextSlide} />
    <div className='fullScreenImage' style={{ position: 'relative' }}>
      {images[slideNumber] && <img src={images[slideNumber].photoUrl} alt='' />}
      {/* Display the caption */}
      <div className='caption text-center' >
        {images[slideNumber] && images[slideNumber].caption}
      </div>
    </div>
  </div>
)}


          {/* Gallery Wrap */}
          <div className='galleryWrap'>
            {images &&
              images.map((slide, index) => (
                <div className='single' key={index} onMouseEnter={() => handleOpenModal(index)}>
                  <img src={slide.photoUrl} alt={slide.caption} />
                </div>
              ))}
          </div>
          <Footer />
        </>
      )}
    </div>
   

  );
};

export default PhotoGallery;
