import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 

import AppContext from '../../contexts/AppContext';
import { FaUserLarge } from "react-icons/fa6";
import { ImFilePdf } from "react-icons/im";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus, FaPencilAlt, FaTrash, FaFileUpload} from 'react-icons/fa';
import sanitizeHtml from 'sanitize-html'; 
import { Audio } from 'react-loader-spinner';
import TabBar from '../../components/TabBar';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill } from 'react-icons/bs';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import './dashboard.css';
import '../../components/values.css';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';
import AddNewsletter from '../../components/newsletter/AddNewsletter';
//import EditNewsletter from '../../components/newsletter/ditNewsletter';
import { ImageConfig } from '../../config/ImageConfig'; 

import AddTestimonial from '../../components/testimonial/AddTestimonial';
import EditTestimonial from '../../components/testimonial/EditTestimonial';
import ImgActionDialog from '../../components/ImgActionDialog';
import EditImage from '../../components/EditImage';
import AddPage from '../../components/AddPage';
import EditPage from '../../components/EditPage';
import AddUser from '../../components/AddUser';
import EditUser from '../../components/EditUser';
import AddImage from '../../components/AddImage';
import AddArticleForm from '../../components/AddArticleForm';
import EditArticle from '../../components/EditArticle';
import ReadMail from '../../components/ReadMail';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog'; // Import the dialog box component
import Footer from '../../components/Footer';
import { FaRegEnvelope, FaRegStar, FaRegTrashAlt } from 'react-icons/fa'; // Icons from react-icons library
import Email from '../../components/Email'; // Component for individual email
//import Modal from 'react-modal'; // Import react-modal


const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);
  const [showImgActionDialog, setShowImgActionDialog] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState('');
  const [param, setParam] = useState({});
  const [target, setTarget] = useState('');
  const [images, setImages] = useState([]);
  // const [newsletters, setNewsletters] = useState([]);
  const [reloadGrid, setReloadGrid] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const { userData, newsletters,enquiries, articles, pages, testimonials,updateCount, setUpdateCount } = useContext(AppContext);
  //  console.log('testimonials',testimonials);
  const navigate = useNavigate(); // Get navigate function from React Router
  

  useEffect(() => {
    setIsLoading(true)
    fetchImages();
    setIsLoading(false)
  }, []);

  

  

 



  const fetchImages = async () => {
    setIsLoading(true);
    const config = { headers: { Authorization: `Bearer ${userData.token}` } };
    try {
      const response = await axios.get('https://www.eldsmw.org:5000/api/images', config);
      const data = response.data;
      setImages(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally{
      setIsLoading(false);
    }
  };

  const fetchFunctions = {
    fetchImages
  };



  const handleImgEdit = () => {
   // console.log('here sir in imgEdit')
    setShowImgActionDialog(false);
    setOpen(false);
    setShowEditImage(true);
  };

 

  const handleImgDelete = async() => {
    setIsLoading(true);
    setShowImgActionDialog(false);
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return; // Exit if user cancels confirmation
    }

    try {
      const response = await axios.delete(`https://www.eldsmw.org:5000/api/images/?id=${param}`, {
        headers: { Authorization: `Bearer ${userData.token}` }, // Add authorization header
      });

      if (response.status === 200) {
        toast.success('Image deleted successfully!');
       /// onImgDelete(); // Call the prop function to trigger deletion in Dashboard
      } else {
        console.error('Error deleting image:', response.data);
        toast.error('Failed to delete image');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error('Failed to delete image');
    }
  
    setIsLoading(false);
    fetchImages();
  };

  

   
  const [selectedEmail, setSelectedEmail] = useState(null);

  const handleEmailClick = (email) => {
    console.log('here sir in',email);
    //setSelectedEmail(email);
    console.log('Navigating to adjust all products page...');
   // navigate('/prices/adjust-all-products-prices'); // Replace with your actual route path
               
  };

  const closeModal = () => {
    setSelectedEmail(null);
  };
  
  const handleNewsClick = (newsId) => {
    setParam(newsId);
  };

  const handleImgClick = (imageId) => {
    setParam(imageId);
    setShowImgActionDialog(true);
  };

  const handleButtonClick = (action,target,rowData) => {

    //console.log('rowData id sir  in button click',rowData.id)
    if(action==='delete'){
      setShowDeleteConfirmationDialog(true)
      setParam(rowData.id);
      console.log('target', target);
      // const functionName = `fetch${target.charAt(0).toUpperCase() + target.slice(1)}`;
      // const targetFunction = fetchFunctions[functionName];
      // console.log('target', targetFunction);
      // if (targetFunction) { targetFunction(); // Call the function dynamically based on the target
      // } else {console.warn(`No function found for target: ${target}`); }
      setUpdateCount(updateCount+1);

    }
    setAction(action);
    setTarget(target);
    setParam(rowData);
    setOpen(true);
  };

  const handleOpenEditImage = (imageId) => {
    // Handle opening EditImage component, potentially passing imageId
    setShowEditImage(true); // Set state to open EditImage modal
  };

  const handleImgCancel = () => {
    setShowImgActionDialog(false); // Set showImgActionDialog to false to close the modal
  };

   // Function to handle cancellation
   const handleCancel = () => {
    setOpen(false);
  };

  // Function to handle permanent deletion
  //  const handlePermanentDelete = () => {
  //   // Perform permanent deletion logic here
  //   console.log('Performing permanent delete for item with ID:', paramId,action,target);
  //   setOpen(false);
  // };

  const handlePermanentDelete = async() => {
    setIsLoading(true)
    let id; 
    //making sure id  is a value not an object
    if (typeof param === 'object' && param !== null) {id = param.id; } else {id = param;} //making sure id 
    setShowDeleteConfirmationDialog(false);
    const message = `Are you sure you want to permanently delete this ${target}  )?`;
    if (!window.confirm(message)) {
      return; // Exit if user cancels confirmation
    }

    try {
      const response = await axios.delete(`https://www.eldsmw.org:5000/api/${target}/?id=${id}`, {
        headers: { Authorization: `Bearer ${userData.token}` }, // Add authorization header
      });
      console.log('status',response)
      if (response.status === 200) {
        toast.success(`${target} deleted successfully!`);
       /// onImgDelete(); // Call the prop function to trigger deletion in Dashboard
      } else {
        console.error(`Error deleting ${target}:`, response.data);
        toast.error(`Failed to delete ${target}`);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error(`Failed to delete ${target}`);
    } finally {
      setIsLoading(false)
      setUpdateCount(updateCount+1 ) 
    }
  };

  

  // Function to handle disabling
  const handleDisable = () => {
    // Perform disable logic here
   // console.log('Disabling item with ID:', paramId);
    setOpen(false);
  };

  
  const data = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
  ]; 

   // Function to limit content to 100 words
   const limitContent = (content) => {
    const words = content.split(' ');
    if (words.length > 30) {
      return words.slice(0, 30).join(' ') + ' . . .';
    }
    return content;
  };

  // Function to sanitize HTML content
  const sanitizeContent = (content) => {
    return sanitizeHtml(content, {
      allowedTags: sanitizeHtml.defaults.allowedTags,
      allowedAttributes: {},
    });
  };



  return (
    <>
     {/* <Dialog open={open} onClose={() => setOpen(false)}>
      <div className="modal-content overflow-y-auto max-h-80vh scrollbar-thin scrollbar-thumb-currentColor scrollbar-track-gray-200">
     
      



      </div>
    </Dialog> */}
    {isLoading && (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
          <Audio type="Bars" color="#FFF" height={50} width={50} />
      </div>
    )}
    <Modal open={showImgActionDialog} onClose={() => setShowImgActionDialog(false)}>
      {/* Content of ImgActionDialog as a modal form */}
      <ImgActionDialog
        onImgEdit={handleImgEdit} // Pass edit handler
        onImgDelete={handleImgDelete} // Pass delete handler
        onImgCancel={handleImgCancel} // Pass cancel handler
        // imageId={paramId} (if needed)
      />
    </Modal>

    {showEditImage && (
      <Modal open={showEditImage} onClose={() => setShowEditImage(false)}>
        <EditImage imageId={param} pages={pages} articles={articles} />
      </Modal>
    )}

    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="modal-content overflow-y-auto max-h-80vh scrollbar-thin scrollbar-thumb-currentColor scrollbar-track-gray-200">
        {action === 'add' && target === 'users' ? <AddUser  /> : null}
        {action === 'edit' && target === 'users' ? <EditUser articleId={param} /> : null}
        {action === 'add' && target === 'articles' ? <AddArticleForm  /> : null}
        {action === 'edit' && target === 'articles' ? <EditArticle rowData={param} /> : null}
        {action === 'add' && target === 'pages' ? <AddPage  /> : null}
        {action === 'edit' && target === 'pages' ? <EditPage rowData={param}  /> : null}
        {action === 'add' && target === 'images' ? <AddImage reloadGrid={fetchImages} pages={pages} articles={articles} /> : null}
        {action === 'add' && target === 'newsletters' ? <AddNewsletter  /> : null}
        
        {action === 'add' && target === 'testimonials' ? <AddTestimonial  /> : null}
        {action === 'edit' && target === 'testimonials' ? <EditTestimonial data={param}  /> : null}

        {action === 'read' && target === 'enquiries' ? <ReadMail rowData={param}  /> : null}

        {action === 'delete' &&   showDeleteConfirmationDialog ? (
          <DeleteConfirmationDialog
            onCancel={handleCancel}
            onPermanentDelete={handlePermanentDelete}
            onDisable={handleDisable}
          />
        ) : null}
      </div>
    </Modal>
      <div className="flex w-full">
        <main className="main-container justify-center mx-auto">
            <div className="flex justify-center mx-auto">
            <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
        
          {activeTab === 'dashboard' && (
          <div className=' text-gray-900'> {/* Add text-gray-900 class */}
            <div className='charts'>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                 width={500}
                 height={500}
                data={data}
                // margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>

        
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
          
          </div>
  </div>




)}


{activeTab === 'pages' && (
  <>
  <div className='px-28 mx-28'>
    <div className="mb-4 ">
      <button
         className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
         onClick={() => handleButtonClick('add','pages', 0)}
      >
        <FaPlus className="mr-1" /> Add page
      </button>
      <div className="relative">
        <input type="text" className="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md text-gray-700" placeholder="Search..." />
        <i className="fa fa-search absolute right-3 top-4 text-gray-700"></i>
      </div>
    </div>
    <div className='flex justify-center items-center'>
      <ToastContainer position="top-center" autoClose={2000} />
        <div className="flex flex-col md:grid md:grid-cols-3 gap-3">
        {/* Declare the variable before the JSX block */}
          {pages && pages.map((page) => {
            const truncatedTitle = page.title ? (page.title.length > 10 ? `${page.title.substring(0, 10)}...` : page.title) : '';
            return (
              <li className="flex bg-gray-200 mt-2 p-2 hover:shadow-lg rounded cursor-pointer transition">
                <div className="ml-2 w-15 h-15"> 
                  <img src={page?.photoUrl}  width="20" height="20" className="rounded"/>
                </div>
                <div className="flex flex-col justify-between ml-2"> {/* Wrap content in a flex container */}
                  <div>
                    <div className="flex items-center"> {/* Wrap the spans in a flex container */}
                      <span className="font-medium  font-bold text-black text-left">Page Name :&nbsp;
                        <span className="text-gray-500">{page.name.charAt(0).toUpperCase() + page.name.slice(1).toLowerCase()}</span>
                      </span>
                    </div>
                    <div className="flex items-center mb-2"> {/* Wrap the spans in a flex container */}
                      <span className="font-medium font-bold text-black text-left">Page Title&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                        <span className="text-gray-500 ">{truncatedTitle.charAt(0).toUpperCase() + truncatedTitle.slice(1).toLowerCase()}</span>
                      {/* <span className="text-gray-400 truncate w-60 text-left">{page.title}</span> */}
                      </span>
                    </div> 
                    <div className=""> 
                      <span className="font-medium text-black">
                      {page && page.pagePhotos && page.pagePhotos.length > 0 && (
                        <img 
                          className="w-full h-full transition duration-300 ease-in-out transform hover:scale-110 click:filter-grayscale" 
                          src={page.pagePhotos[0]?.photoUrl} 
                          alt={`Photo ${page.pagePhotos[0]?.caption}`} 
                          style={{ objectFit: 'cover', width: '100%', height: '100%', padding: '10px' }} 
                        />
                      )}
                       
                      </span> 
                      <p className='font-bold text-black '>Description</p>
                      <div className="flex items-start"> {/* Use items-start to ensure the description starts from the top */}
                        
                        <div className="flex flex-col flex-1"> {/* Use flex-1 to make the description fill the remaining space */}
                          {/* Render the truncated description */}
                          <span className="text-gray-500 text-justify"dangerouslySetInnerHTML={{ __html: limitContent(page.description || '') }}></span> 
                          {/* <span className="absolute left-0 bottom-0 text-gray-500" dangerouslySetInnerHTML={{ __html: limitContent(page.description || '') }}></span>  */}
          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-end gap-4'> {/* Align buttons at the bottom */}
                    <button
                      className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                      onClick={() => handleButtonClick('edit', 'pages', page)}
                    >
                      <FaPencilAlt className="mr-1" /> 
                    </button>
                    <button
                      className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                      onClick={() => handleButtonClick('delete', 'pages', page)}
                    >
                      <FaTrash className="mr-1" />
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  </>
)}



    
{activeTab === 'article' && (
  <>
  <div className='px-28 mx-28'>
    <div className="mb-4 ">
      <button
         className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
         onClick={() => handleButtonClick('add','articles', 0)}
      >
        <FaPlus className="mr-1" /> Add Article
      </button>
      <div className="relative">
        <input type="text" className="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md text-gray-700" placeholder="Search..." />
        <i className="fa fa-search absolute right-3 top-4 text-gray-700"></i>
      </div>
    </div>
    <div className='flex justify-center items-center'>
      <ToastContainer position="top-center" autoClose={2000} />
        <div className="flex flex-col md:grid md:grid-cols-3 gap-3">
        {/* Declare the variable before the JSX block */}
          {articles && articles.map((article) => {
              // Convert article date string to a Date object
          const articleDate = new Date(article.publish_date);  
          // Calculate the difference in milliseconds between article date and current date
          const timeDifference = Date.now() - articleDate.getTime();
          // Convert milliseconds to days
          const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const truncatedTitle = article.title ? (article.title.length > 100 ? `${article.title.substring(0, 10)}...` : article.title) : '';
            return (
              <li className="flex bg-gray-200 mt-2 p-2 hover:shadow-lg rounded cursor-pointer transition">
                
                <div className="flex flex-col justify-between ml-2"> {/* Wrap content in a flex container */}
                  <div>
                    <div className="flex flex-col"> 
                      <span className="font-medium text-black text-sm">
                      <img src={article?.articlePhotos?.[0]?.photoUrl}  width="20" height="20" className="w-10 h-12 rounded"/>
                        <span className='pl-2 text-uppercase font-bold '> 
                      {truncatedTitle.toUpperCase()}
                      </span>
                      </span> 
                      <div className="flex items-start"> {/* Use items-start to ensure the description starts from the top */}
                        
                        <div className="flex flex-col flex-1"> {/* Use flex-1 to make the description fill the remaining space */}
                          {/* Render the truncated description */}
                          <span className=" text-center p-2 text-blue-500">Posted {`${daysDifference} day${daysDifference === 1 ? '' : 's'} ago`}</span>
                          <span className="text-gray-500 text-justify"dangerouslySetInnerHTML={{ __html: limitContent(article.content || '') }}></span> 
                          {/* <span className="absolute left-0 bottom-0 text-gray-500" dangerouslySetInnerHTML={{ __html: limitContent(page.description || '') }}></span>  */}
          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-end space-x-4'> {/* Align buttons at the bottom */}
                    <button
                      className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                      onClick={() => handleButtonClick('edit', 'articles', article)}
                    >
                      <FaPencilAlt className="" /> 
                    </button>
                    <button
                      className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                      onClick={() => handleButtonClick('delete', 'articles', article)}
                    >
                      <FaTrash className="" />
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  </>
)}


   
{activeTab === 'newsletter' && (
            <>
            <div className="mx-28 px-28">
            <div className="mb-4 ">
      <button
        className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
        onClick={() => handleButtonClick('add','newsletters', 0)}
      >
        <FaPlus className="mr-1" /> New Newsletter
      </button>
      <div className="relative">
                <input type="text" className="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md text-gray-700" placeholder="Search..." />
                <i className="fa fa-search absolute right-3 top-4 text-gray-700"></i>
              </div>
    </div>
            
            <div className='flex justify-center items-center'>
              <ToastContainer position="top-center" autoClose={2000} />
              <div className="flex flex-col md:grid md:grid-cols-3 gap-3">
                {newsletters && newsletters.map((news) => (
                  <div key={news.id} className="mx-1 py-2 px-2 border border-2 border-blue-500 justify-center rounded-lg text-center flex flex-col items-center w-40 h-30">
                    {/* <img
                      src={ImageConfig[news.file_name.split('/')[1]] || ImageConfig['default']}
                      alt=""
                      style={{ width: '50px', height: '50px' }} // Adjust width and height as needed
                    /> */}
                 
                    <ImFilePdf size={20} color='#FF0000'/>
                    




                    <div className="text-gray-500  items-center justify-center">
                      <p>
                          {news.file_name.toLowerCase().length > 20
                              ? news.file_name.toLowerCase().substring(0, 17) + '...'
                              : news.file_name.toLowerCase()}
                      </p>
                  
                      <p>{(news.file_size / 1024).toFixed(2)} KB</p>
                      <div className='flex justify-center'> {/* Align buttons at the bottom */}
                        <button
                          className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                          onClick={() => handleButtonClick('delete', 'newsletters', news)}
                        >
                          <FaTrash className="mr-1" />
                        </button>
                      </div>
                      
                    </div>
                </div>

                      ))
                  }

              </div>
              

            </div>
            </div>
            
            </>
          )}




    
{activeTab === 'user' && (
  <>
  <div className='px-28 mx-28'>
    <div className="mb-4 ">
      <button
         className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
         onClick={() => handleButtonClick('add','articles', 0)}
      >
        <FaPlus className="mr-1" /> Add user
      </button>
      <div className="relative">
        <input type="text" className="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md text-gray-700" placeholder="Search..." />
        <i className="fa fa-search absolute right-3 top-4 text-gray-700"></i>
      </div>
    </div>
    <div className='flex justify-center items-center'>
      <ToastContainer position="top-center" autoClose={2000} />
        <div className="flex flex-col md:grid md:grid-cols-3 gap-3">
        {/* Declare the variable before the JSX block */}
          {articles && articles.map((article) => {
              // Convert article date string to a Date object
          const articleDate = new Date(article.publish_date);  
          // Calculate the difference in milliseconds between article date and current date
          const timeDifference = Date.now() - articleDate.getTime();
          // Convert milliseconds to days
          const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const truncatedTitle = article.title ? (article.title.length > 100 ? `${article.title.substring(0, 10)}...` : article.title) : '';
            return (
              <li className="flex bg-gray-200 mt-2 p-2 hover:shadow-lg rounded cursor-pointer transition">
                <div className="flex flex-col justify-between ml-2"> {/* Wrap content in a flex container */}
                  <div>
                  <div className="flex ml-2">
              <img
                src="https://i.imgur.com/aq39RMA.jpg"
                width="55px"
                height="55px"
                className="rounded-full"
                // Optional: Set max-width and max-height for additional control
                style={{ maxWidth: '75px', maxHeight: '75px' }}  
              />
              <div className="flex flex-col ml-2">
                <span className="font-medium text-black">Jessica Koel</span>
                <span className="text-sm text-gray-400 truncate w-32">M & E Specialist</span>
              </div>

  
</div>
<div className="ml-2">
<span className="font-medium">
  <span className="text-black">Role: </span>
  <span className="text-gray-400">System Admin</span>
</span>

</div>


                  </div>
                  <div className='flex flex-end'> {/* Align buttons at the bottom */}
                    <button
                      className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                      onClick={() => handleButtonClick('edit', 'pages', article)}
                    >
                      <FaPencilAlt className="mr-1" /> 
                    </button>
                    <button
                      className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                      onClick={() => handleButtonClick('delete', 'pages', article)}
                    >
                      <FaTrash className="mr-1" />
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  </>
)}








    
{activeTab === 'enquiry' && (
  <>
  return (
    <div className="flex h-screen bg-red">
      {/* Sidebar */}
      <div className=" flex-1 p-4 rounded-lg overflow-y-auto">
        <div className="flex items-center justify-between mb-0">
          <div className="text-xl font-semibold text-gray-800">Email</div>
          <div className="flex items-center space-x-2">
            <FaRegStar className="text-yellow-500 cursor-pointer" />
            <FaRegEnvelope className="text-gray-600 cursor-pointer" />
            <FaRegTrashAlt className="text-gray-600 cursor-pointer" />
          </div>
        </div>
        <div className='p-4 border border-gray-300 rounded-lg' >emails
          {enquiries.map((email) => (
            <Email
              key={email.id}
              email={email}
              isSelected={selectedEmail && selectedEmail.id === email.id}
              //onClick={() => handleEmailClick(email)}
              onClick={() => handleButtonClick('read', 'enquiries', email)}
            />
          ))}
        </div>
        {/* Modal (conditionally rendered) */}
      
      </div>

      
    </div>
          );
 
  </>
)}

{activeTab === 'testimonial' && (
  <>
    <div className='px-28 mx-28'>
        <div className="mb-4 ">
          <button
            className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
            onClick={() => handleButtonClick('add','testimonials', 0)}
          >
            <FaPlus className="mr-1" /> Add Testimonial
          </button>
          <div className="relative">
            <input type="text" className="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md text-gray-700" placeholder="Search..." />
            <i className="fa fa-search absolute right-3 top-4 text-gray-700"></i>
          </div>
        </div>
        <div className="-mx-3 md:flex items-start">
          {testimonials && testimonials.map((testimonial) => (
            <div className="px-3 md:w-1/3"key={testimonial.id}>
              <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                <div className="w-full flex mb-4 items-center">
                  <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                    <img src={testimonial?.photo_url} alt=""/>
                  </div>
                  <div className="flex-grow pl-3">
                    <h6 className="font-bold text-sm uppercase text-gray-600">{testimonial.name}</h6>
                  </div>
                </div>
                <div className="w-full">
                    <p className="text-sm leading-tight"dangerouslySetInnerHTML={{ __html: limitContent(testimonial.content || '') }}></p>   
                </div>
                <div className='flex flex-end gap-2'> {/* Align buttons at the bottom */}
                  <button
                    className="flex justify-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                    onClick={() => handleButtonClick('edit', 'testimonials', testimonial)}
                  >
                    <FaPencilAlt className="mr-1" /> 
                  </button>
                  <button
                    className="flex items-center rounded-md p-1 text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                    onClick={() => handleButtonClick('delete', 'testimonials', testimonial)}
                  >
                  <FaTrash className="mr-1" />
                </button>
              </div>
            </div>
          </div>
            ))}
      </div>
    </div>
  </>
)}








          {activeTab === 'images' && (
            <>
            <div className="mx-28 px-28">
            <div className="mb-4 ">
              <button
                className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
                onClick={() => handleButtonClick('add','images', 0)}
              >
                <FaPlus className="mr-1" /> Add Image
              </button>
              <div className="relative">
                <input type="text" className="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md text-gray-700" placeholder="Search..." />
                <i className="fa fa-search absolute right-3 top-4 text-gray-700"></i>
              </div>
            </div>
            
            <div className='flex justify-center items-center'>
               
              <ToastContainer position="top-center" autoClose={2000} />
             
              <div className="flex flex-col md:grid md:grid-cols-3 gap-3">
                {images && images.map((image) => (
                  <div
                    key={image.id}
                    className="relative rounded overflow-hidden"
                    style={{ width: '100%', maxWidth: '400px', height: '300px', position: 'relative' }}
                    onClick={() => handleImgClick(image.id)}
                  >
                    <img 
                 
                  
                      className="w-full h-full transition duration-300 ease-in-out transform hover:scale-110 click:filter-grayscale" 
                      src={image?.photoUrl} 
                      
                      alt={`Photo ${image?.caption}`} 
                      style={{ objectFit: 'cover', width: '100%', height: '100%', padding: '10px' }} 
                    />
                    <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium transform transition duration-300 ease-in-out hover:bg-opacity-60">
                     {image.caption}
                    </p>
                  </div>
                 ))}
                </div>

            </div>
            </div>
            
            </>
          )}
           <Footer/>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
